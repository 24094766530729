<script>
import AppBanner from '@/components/shared/AppBanner';
import Button from '../components/reusable/Button.vue';
import AppFooter from '@/components/shared/AppFooter';


export default {
	name: 'Home',
	components: {
		AppBanner,
		Button,
		AppFooter,
	},
};
</script>

<template>
	<div class="container">
		<!-- Banner -->
		<AppBanner />

		<!-- Card Container Start -->
		<section id="bannersec" class="flex flex-row items-end">
			<div class="w-full md:w-1/3 mt-12 ml-12">
				<div class="md:justify-start mb-10 sm:mt-20 justify-center flex">
						<router-link to="/botoniel"
							class="font-bold items-left px-14 py-4 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
							aria-label="BotOniel">
							<Button title="Akses BotOniel di sini" />
						</router-link>
					</div>
				<h1
					class="font-bold text-3xl md:text-3xl xl:text-4xl text-center md:text-left text-black mb-10 mt-10">
					BotOniel: Jokes Generator
				</h1>
				<p
					class="font-general-medium mt-2 text-2xl md:text-3xl xl:text-4xl text-center md:text-left text-black mt-10">
					“A good laugh overcomes more difficulties and dissipates more dark clouds than any other one thing”
				</p>
				<p
					class="font-bold xl:mt-2 text-lg sm:text-xl xl:text-xl text-center md:text-left leading-none md:text-primary-dark text-white mb-28 mt-5">
					Laura Ingalls Wilder
				</p>
			</div>
		</section>

		<!-- Card Container Third -->
		<section id="bannerthird" class="flex flex-row items-end">
			<section class="flex flex-col sm:justify-between items-center sm:flex-row">
				<div class="w-full md:ml-10 mb-24 md:w-1/3 items-center">
					<div class="md:justify-start sm:mb-20 sm:mt-20 justify-center flex">
						<router-link to="/what-song-oniel-play"
							class="font-bold items-left px-14 py-4 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
							aria-label="WSOP">
							<Button title="What Song Oniel Play?" />
						</router-link>
					</div>
					<h1 class="font-bold text-3xl md:text-3xl xl:text-4xl text-center md:text-left text-black mb-10 mt-10">
						What Song Oniel Play?
					</h1>
					<h2 class="font-general-medium mt-2 text-2xl md:text-3xl xl:text-4xl text-center md:text-left text-black mt-10
				">
						“Music expresses that which cannot be put into words and that which cannot remain silent.”
					</h2>
					<p
						class="font-bold mt-2 text-lg sm:text-xl xl:text-xl text-center md:text-left leading-none text-black mt-10 mb-10">
						Victor Hugo
					</p>
				</div>
			</section>
		</section>

		<!-- Card Container Footer -->
		<section id="bannerfour" class="flex flex-row items-end">
			<div class="w-full md:ml-10 content-end">
				<div class="mb-5 sm:mb-20 sm:mt-20 justify-center flex">
					<router-link to="/botoniel"
						class="font-bold items-right px-32 py-4 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
						aria-label="BotOniel">
						<Button title="BotOniel" />
					</router-link>
				</div>
				<div class="mb-5 sm:mb-20 sm:mt-20 justify-center flex">
					<router-link to="/what-song-oniel-play"
							class="font-bold items-left px-16 py-4 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
							aria-label="WSOP">
							<Button title="What Song Oniel Play?" />
						</router-link>
				</div>
				<div class="mb-5 sm:mb-20 sm:mt-20 justify-center flex">
					<a class="font-bold items-right px-28 py-4 rounded-full bg-[#0093B9] text-white text-xl duration-300"
						aria-label="WhatsNext">
						<Button title="What's Next?" />
					</a>
				</div>
			</div>
		</section>
	</div>

	<AppFooter class="items-center" />

</template>

<style scoped>
#bannersec {
	background: url(@/assets/images/background-images/oniel-2.jpg);
	width: 100vw;
	height: 100vh;
	/* Adjust the height as needed */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

#bannerthird {
	background-image: url(@/assets/images/background-images/oniel-3.jpg);
	width: 100vw;
	height: 100vh;
	/* Adjust the height as needed */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

#bannerfour {
	background-image: url(@/assets/images/background-images/oniel-4.jpeg);
	width: 100vw;
	height: 100vh;
	/* Adjust the height as needed */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
</style>

<!--
<section id="bannerfour" class="flex flex-col md:items-end items-center sm:justify-center sm:flex-row">
			<div class="w-full md:ml-10">
				<div class="mb-10 sm:mb-20 sm:mt-20 justify-center flex">
					<router-link to="/botoniel"
						class="font-bold items-right px-36 py-14 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-3xl duration-300"
						aria-label="BotOniel">
						<Button title="BotOniel" />
					</router-link>
				</div>
				<div class="mb-10 sm:mb-20 sm:mt-20 justify-center flex">
					<a href="http://bit.ly/PlaylistnyaOniel"
						class="font-bold items-right px-14 py-14 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-3xl duration-300"
						aria-label="WhatSongOnielPlay">
						<Button title="What Song Oniel Play?" />
					</a>
				</div>
				<div class="mb-10 sm:mb-20 sm:mt-20 justify-center flex">
					<a class="font-bold items-right px-28 py-14 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-3xl duration-300"
						aria-label="WhatsNext">
						<Button title="What's Next?" />
					</a>
				</div>
			</div>
		</section>
	</div>
-->