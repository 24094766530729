<script>
import AppHeader from '@/components/shared/AppHeader';
import AppFooter from '@/components/shared/AppFooter';
import axios from 'axios';

export default {
  name: 'FindSong',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      keyword: '',
      data: [],
      filteredData: [],
      currentPage: 1,
      itemsPerPage: 20,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredData.slice(start, end);
    }
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.post('https://script.google.com/macros/s/AKfycbwjI9smTgy6zaHyhHUsdGjx5WtEcL2teXQ8YhgVL_C59gGeX0ArG5IAkS3Cs3O7M0W0bQ/exec', null, {
          params: { keyword: this.keyword }
        });
        this.data = response.data;
        this.filteredData = this.data;
        this.currentPage = 1; // reset to first page when new data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    search() {
      if (this.keyword.trim() === '') {
        this.filteredData = this.data;
      } else {
        this.fetchData();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('id-ID', options);
    },
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<template>
  <!-- App Header -->
  <AppHeader class="items-center mb-24 px-10" />

  <!-- Card Container Start -->
  <div class="container mx-auto">
    <div class="text-center border-b border-primary-light
					dark:border-secondary-dark">
      <p
        class="font-general-semibold text-2xl sm:text-5xl font-semibold text-ternary-dark dark:text-ternary-light mb-4">
        What Song Oniel Play?
      </p>
      <h3 class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mb-4
				">
        Cek apakah Oniel pernah memutar lagu ini atau belum?
      </h3>
    </div>
    <!-- Fitur Cari -->
    <!-- Percobaan -->
    <div class="mx-auto max-w-screen-xl px-4 lg:px-12 mt-10">
      <!-- Start coding here -->
      <div class="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
        <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
          <div class="w-full md:w-1/2">
            <form class="flex items-center">
              <label for="simple-search" class="sr-only">Search</label>
              <div class="relative w-full">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                    viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
                <input
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  v-model="keyword" @input="search" type="text" placeholder=" Cari judul atau artis">
              </div>
            </form>
          </div>
        </div>
        <div class="overflow-x-auto">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-4 py-3">Title Song</th>
                <th scope="col" class="px-4 py-3">Artist</th>
                <th scope="col" class="px-4 py-3">Platform</th>
                <th scope="col" class="px-4 py-3">Live Date</th>
                <th scope="col" class="px-4 py-3">Link</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in paginatedData" :key="index" class="border-b dark:border-gray-700">
                <td class="px-4 py-3">{{ row.title }}</td>
                <td class="px-4 py-3">{{ row.artist }}</td>
                <td class="px-4 py-3">{{ row.platform }}</td>
                <td class="px-4 py-3">{{ formatDate(row.date) }}</td>
                <td class="px-2 py-3">
                    <a :href="row.link" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Search on Spotify</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <nav class="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
          aria-label="Table navigation">
          <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
            Showing page
            <span class="font-semibold text-gray-900 dark:text-white">{{ currentPage }}</span>
            of
            <span class="font-semibold text-gray-900 dark:text-white">{{ totalPages }}</span>
          </span>
          <ul class="inline-flex items-stretch -space-x-px">
            <li>
              <button @click="prevPage" :disabled="currentPage === 1"
                class="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <span class="sr-only">Previous</span>
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
              </button>
            </li>
            <li>
              <button @click="nextPage" :disabled="currentPage === totalPages"
                class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <span class="sr-only">Previous</span>
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd" />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<style scoped></style>