<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section class="flex flex-col sm:justify-between items-center sm:flex-row">
			<div class="w-full md:ml-10 md:w-1/3 items-center mt-48">
			<img src="@/assets/images/logo-dark.png" class="w-80">
			<p
				class="font-general-medium mt-2 text-lg sm:text-xl xl:text-2xl text-center md:text-left text-white mt-12 mb-10">
				Documenting all the little efforts to support her. Appreciation for the many things she has done to pursue her dreams
			</p>
			<p
				class="font-bold mt-2 text-lg sm:text-xl xl:text-2xl text-center md:text-left leading-none text-white mt-10 mb-10">
				Walk Together and Let's Start Now!
			</p>
		</div>
	</section>
</template>

<style scoped>
section {
  background-image: url(@/assets/images/background-images/oniel-1.jpg);
  width: 100vw;
  height: 120vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
